// apiPointsHandlers
import createdSupabaseClient from 'src/handlers/supabaseClient';
import IUserMilestonesPoints from './IUserMilestonesPoints';

/**
 * NAME: setXFollowing
 * @param val boolean value to assign
 * @param usr_id personal id of the caller
 * @returns updated row
 */
const setXFollowing = async (val: boolean, usr_id: string) => {
  const tw_id = await createdSupabaseClient
    .from('usermilestonespoints')
    .update({ isfollowingx: val })
    .eq('user_id', usr_id)
    .select();
  return tw_id;
}; // setXFollowing

/**
 * NAME: setTelegramFollowing
 * @param val boolean value to assign
 * @param usr_id personal id of the caller
 * @returns updated row
 */
const setTelegramFollowing = async (val: boolean, usr_id: string) => {
  const tw_id = await createdSupabaseClient
    .from('usermilestonespoints')
    .update({ isfollowingtelegram: val })
    .eq('user_id', usr_id)
    .select();
  return tw_id;
}; // setTelegramFollowing

/**
 * NAME: setHasPublishedOnX
 * @param val boolean value to assign, could be null if any
 * @param usr_id personal user_id of the caller
 * @returns updated row
 */
const setHasPublishedOnX = async (val: boolean | null, usr_id: string) => {
  const tw_id = await createdSupabaseClient
    .from('usermilestonespoints')
    .update({ haspublishedonx: val })
    .eq('user_id', usr_id)
    .select();
  return tw_id;
}; // setHasPublishedOnX

/**
 * NAME: setHasRetweettedOnX
 * @param val boolean value to assign, could be null if any
 * @param usr_id personal user_id of the caller
 * @returns updated row
 */
const setHasRetweettedOnX = async (val: boolean | null, usr_id: string) => {
  const tw_id = await createdSupabaseClient
    .from('usermilestonespoints')
    .update({ hasretweetted: val })
    .eq('user_id', usr_id)
    .select();
  return tw_id;
}; // setHasRetweettedOnX

/**
 * NAME: setDailyCheck
 * @param val boolean to assign, could be null if any
 * @param usr_id personal user_id of the caller
 * @returns updated row
 */
const setDailyCheck = async (val: boolean | null, usr_id: string) => {
  const tw_id = await createdSupabaseClient
    .from('usermilestonespoints')
    .update({ hascheckeddaily: val })
    .eq('user_id', usr_id)
    .select();
  return tw_id;
}; // setDailyCheck

/**
 * NAME: setPurchaseCheck
 * @param val boolean to assign, could be null if any
 * @param usr_id personal user_id of the caller
 * @returns updated row
 */
const setPurchaseCheck = async (val: boolean | null, usr_id: string) => {
  const tw_id = await createdSupabaseClient
    .from('usermilestonespoints')
    .update({ hasdailypurchased: val })
    .eq('user_id', usr_id)
    .select();
  return tw_id;
}; // setPurchaseCheck

export default {
  setXFollowing,
  setHasPublishedOnX,
  setHasRetweettedOnX,
  setTelegramFollowing,
  setDailyCheck,
  setPurchaseCheck
};
