import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import supabaseClient from 'src/handlers/supabaseClient';

function LoginTwitterAuth0() {
  const [t] = useTranslation();
  useEffect(() => {
    (async () => {
      const currPt = window.localStorage.getItem('referalCode');
      if (!currPt) {
        const tmp = window.location.pathname.split('/');
        const pth =
          tmp.at(-1) !== 'dashboards' && tmp.at(-1) !== 'login'
            ? tmp.at(-1)
            : null;
        if (pth) window.localStorage.setItem('referalCode', pth);
      }
    })();
  }, []);
  return (
    <Auth
      supabaseClient={supabaseClient}
      appearance={{ theme: ThemeSupa }} // #0b1a32
      theme="dark"
      providers={['twitter']}
      // TODO: decommentare => onlyThirdPartyProviders
      // onlyThirdPartyProviders
      redirectTo={`${process.env.REACT_APP_BASE_URL}/extended-sidebar/dashboards/crypto`}
      localization={{
        variables: {
          sign_in: {
            email_label: t('email_label'),
            password_label: t('password_label')
          }
        }
      }}
    />
  );
}

export default LoginTwitterAuth0;
