import { Box, Card, Typography, Container, styled } from '@mui/material';

import { useTranslation } from 'react-i18next';
import Logo from 'src/components/LogoSign';

import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import Auth0TwitterLogin from '../LoginTwitterAuth0';
import twitter_logo from 'src/assets/twitter_logo.png';
import { bgHeaderCrypto } from 'src/content/dashboards/Crypto';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

function LoginBasic() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Twitter - Login</title>
      </Helmet>
      <MainContent>
        <Box
          sx={(theme) => ({
            mr: 2,
            py: 1,
            width: '100%',
            height: theme.spacing(8),
            bgcolor: bgHeaderCrypto
          })}
        >
          <Logo />
        </Box>
        <TopWrapper>
          <Container maxWidth="sm">
            <Card
              sx={(theme) => ({
                mt: 1,
                px: 4,
                pt: 3,
                pb: 3,
                bgcolor: theme.general.loginBg
              })}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1,
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1
                  }}
                >
                  {t('Sign in')}
                  <img
                    src={twitter_logo}
                    alt="twitter-logo"
                    height={25}
                    width={25}
                  />
                </Typography>
                <Typography
                  variant="h4"
                  color="common.white"
                  fontWeight="normal"
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  {t(
                    'Fill in the fields below or press Social button to sign into your account.'
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  color="warning.main"
                  fontWeight="bold"
                  fontSize={10}
                  sx={{
                    mb: 3,
                    textAlign: 'center'
                  }}
                >
                  {t('DISCLAMER-TWITTER-LOGIN')}
                </Typography>
              </Box>
              <Auth0TwitterLogin />
            </Card>
          </Container>
        </TopWrapper>
      </MainContent>
    </>
  );
}

export default LoginBasic;
