import createdSupabaseClient from 'src/handlers/supabaseClient';
import { Tweet } from 'react-tweet/api';

/**
 * @returns burnNext url tweet otherwise tweetter page
 */
const getBurnextTwUrl = async () => {
  const def = 'x.com';
  const { data, error } = await createdSupabaseClient
    .from('twitte_bk')
    .select('data')
    .limit(1)
    .single();
  if (error) return def;
  const val: Tweet = JSON.parse(data?.data || '{}');
  return val?.entities?.urls?.[0]?.url || def;
}; // getBurnextTwUrl

export default getBurnextTwUrl;
