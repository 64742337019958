import { createContext, useEffect, useState } from 'react';
import createdSupabaseClient from './handlers/supabaseClient';
import { Session } from '@supabase/supabase-js';
import { AuthProvider } from './contexts/TayAuthContext';
import App from './App';

export const SessionContext = createContext<Session | null>(null);

const AppLoader = () => {
  const [ss, setSession] = useState<Session | null>(null);

  useEffect(() => {
    // Register this immediately after calling createClient!
    // Because signInWithOAuth causes a redirect, you need to fetch the
    // provider tokens from the callback.
    const subscription = createdSupabaseClient.auth.onAuthStateChange(
      (event, session) => {
        if (session) {
          if (session.provider_refresh_token) {
            window.localStorage.setItem(
              'oauth_provider_refresh_token',
              session.provider_refresh_token
            );
          }
          if (session.provider_token) {
            window.localStorage.setItem(
              'oauth_provider_token',
              session.provider_token
            );
          }
          setSession(session);
        }

        if (event === 'SIGNED_IN' && session) {
          const currRc = window.localStorage.getItem('referalCode');
          if (currRc) {
            (async () => {
              const { error } = await createdSupabaseClient.rpc(
                'add_refcode_by_user_id',
                {
                  ref_code: currRc,
                  ref_user_id: session.user.id
                }
              );
              if (error) console.log('ERRORE: ', error);
            })();
          }
        }

        if (event === 'SIGNED_OUT') {
          setSession(null);
          window.localStorage.removeItem('referalCode');
          window.localStorage.removeItem('tw_url');
          window.localStorage.removeItem('writeX');
          window.localStorage.removeItem('oauth_provider_token');
          window.localStorage.removeItem('oauth_provider_refresh_token');
        }
      }
    );

    return () => {
      subscription.data.subscription.unsubscribe();
    };
  }, []);

  return (
    <SessionContext.Provider value={ss}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </SessionContext.Provider>
  );
};
export default AppLoader;
