import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import Footer from 'src/components/Footer';

import AccountBalance from './AccountBalance';
import Wallets from './Wallets';
import AccountSecurity from './AccountSecurity';
import RecentOrders from './RecentOrders';
import WatchList from './WatchList';
import PageHeader from './PageHeader';
import BurnNextBox from './BurnNextBox';
// import sfondo from 'src/assets/bakgrd.png';
import sfondo from 'src/assets/bakgrd0.jpeg';
import useAuth from 'src/hooks/useAuth';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { TwitterShareButton } from 'react-share';
import { useEffect, useState } from 'react';
import getWriteX from './BurnNextBox/apiFunctions/getWriteX';
import apiPointsHandlers from './BurnNextBox/apiPointsHandlers';
import createdSupabaseClient from 'src/handlers/supabaseClient';
import { Database } from './BurnNextBox/IDatabase';
import { Check } from '@mui/icons-material';

export const bgHeaderCrypto = '#1f2d4a !important';
export const baseCryptoUrl = '/extended-sidebar/dashboards/';

const blackShadow = '2px 2px #00000074';
const LINKS = [
  'daily-check',
  'like-retweets',
  'create-writex-post',
  'daily-purchase'
];
const LINKS_2 = [1, 2];

type IMil = Database['public']['Tables']['usermilestonespoints']['Row'];
interface IMilestone extends IMil {}
interface IMissionDialog {
  disableMsg?: boolean;
  open?: boolean;
  handler?: () => void;
  mission?: string;
}

export const GeneralDialog = ({
  open,
  handler,
  mission,
  disableMsg
}: IMissionDialog) => {
  return open ? (
    <Dialog open={open} disableEscapeKeyDown onBackdropClick={handler}>
      <DialogTitle sx={{ bgcolor: bgHeaderCrypto, mb: 2 }}>
        <Typography variant="h4" align="center" color="white">
          {!!disableMsg ? 'Message' : 'Daily Mission'}
        </Typography>
      </DialogTitle>
      <Typography variant="h5" align="center" sx={{ px: 2 }}>
        {!!disableMsg
          ? mission
          : 'Congratulations your ' + mission + ' mission has been a SUCCESS!'}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Button variant="contained" onClick={handler} sx={{ my: 2 }}>
          Confirm
        </Button>
      </Box>
    </Dialog>
  ) : null;
};

function DashboardCrypto() {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [refCode, setRefCode] = useState<string | undefined>();
  const [mileston, setMilestone] = useState<IMilestone | null>();
  const [dialogMission, setDialogMission] = useState<
    IMissionDialog | undefined
  >();
  const navigate = useNavigate();
  const [writeX, setWriteX] = useState<
    { body: string; hash?: string | null } | undefined
  >(undefined);
  const isAdmin = user?.role_id === 1;

  const handleDailyCheck = async () => {
    if (user) {
      const res = await apiPointsHandlers.setDailyCheck(true, user.id);
      if (res.error) console.log('ERROR: ', res.error);
      setDialogMission(undefined);
    } else {
      console.log('User not defined: ', user);
    }
  }; // handle daily check

  useEffect(() => {
    (async () => {
      // Milestons Points
      if (user?.id) {
        const tw_id = await createdSupabaseClient
          .from('usermilestonespoints')
          .select('*')
          .eq('user_id', user.id)
          .limit(1)
          .single();

        if (tw_id.error) console.log('Milestones Points ERROR: ', tw_id.error);
        setRefCode(tw_id.data?.personal_ref || '');
        setMilestone(tw_id.data);
      } else {
        console.log('Error: User id non disponibile in Crypto.tsx');
      } // if - has user
      // Write X
      const r = await getWriteX();
      const wxr = {
        body: r?.body || '',
        hash: r?.hashtags || null
      };
      window.localStorage.setItem('writeX', JSON.stringify(wxr));
      if (r) setWriteX(wxr);
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>Crypto Dashboard</title>
      </Helmet>
      <GeneralDialog
        open={dialogMission?.open}
        handler={dialogMission?.handler}
        mission={dialogMission?.mission}
        disableMsg={dialogMission?.disableMsg}
      />
      <Box
        sx={{
          backgroundImage: 'url(' + sfondo + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          // backgroundSize: 'auto 100%',
          // height: '1200px', // da cancellare o mettere in auto
          width: '100%', // da cancellare o mettere in auto
          // height: '100vh',
          height: 'calc(100%)',
          color: 'white',
          display: 'flex',
          gap: 2
        }}
      >
        {/* <img
            src={sfondo}
            alt="background"
            height="auto"
            width={window.visualViewport?.width}
          /> */}
        <Grid container direction="column" sx={{ width: '100%' }}>
          <Grid item container alignItems="center">
            <Grid
              item
              sx={{
                pl: 'calc(9%)',
                pt: 'calc(5.5%)',
                textShadow: blackShadow
              }}
            >
              <Typography variant="h3" color="white">
                {user?.user_metadata.user_name || 'No Name'}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            sx={{ pt: 'calc(4%)' }}
          >
            <Grid
              item
              sx={{
                pl: 'calc(19%)',
                pt: 'calc(5.5%)',
                textShadow: blackShadow
              }}
            >
              <Typography
                sx={{ fontSize: 16, fontWeight: 'bold' }}
                color="white"
              >
                {mileston?.rank || 0}°
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                pr: 'calc(10%)',
                pt: 'calc(5%)',
                textShadow: blackShadow
              }}
            >
              <Typography
                color="white"
                sx={{ fontSize: 18, fontWeight: 'bold' }}
              >
                {mileston?.totalpoints || 0}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            sx={{
              pr: 'calc(8%)',
              mt: 'calc(27%)'
            }}
          >
            {LINKS.map((l, ix) => (
              <Grid
                key={ix + '_'}
                item
                sx={{
                  mb: 'calc(14.5%)'
                }}
                onClick={
                  l === 'create-writex-post' && !isAdmin
                    ? undefined
                    : async () => {
                        if (l === 'daily-check') {
                          setDialogMission({
                            handler: handleDailyCheck,
                            mission: 'daily check',
                            open: true
                          });
                          return undefined;
                        }
                        if (l === 'daily-purchase') {
                          setDialogMission({
                            disableMsg: true,
                            handler: () => {
                              setDialogMission(undefined);
                            },
                            mission: 'COMING SOON',
                            open: true
                          });
                          return undefined;
                        }
                        navigate(baseCryptoUrl + l);
                      }
                }
              >
                {l === 'create-writex-post' && !isAdmin ? (
                  <TwitterShareButton
                    title={`${writeX?.body}\n\n`}
                    hashtags={
                      writeX?.hash ? writeX?.hash.split(',') : undefined
                    }
                    url={window.localStorage.getItem('tw_url') + '\n'}
                    style={{ width: '100%' }}
                    onShareWindowClose={() => {
                      (async () => {
                        if (user) {
                          const res =
                            await apiPointsHandlers.setHasPublishedOnX(
                              true,
                              user.id
                            );
                          if (res.error) console.log('ERROR: ', res.error);
                        } else {
                          console.log('route: ', l);
                          console.log('User not defined: ', user);
                        }
                      })();
                    }}
                  >
                    <Typography
                      color="white"
                      textAlign="right"
                      sx={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: 'yellow',
                        textShadow: blackShadow
                      }}
                    >
                      {mileston?.haspublishedonx &&
                      l === 'create-writex-post' ? (
                        <Check
                          color="success"
                          sx={{ textShadow: blackShadow, fontWeight: 'bold' }}
                        />
                      ) : (
                        t('LETSGO')
                      )}
                    </Typography>
                  </TwitterShareButton>
                ) : (
                  <Typography
                    color="white"
                    textAlign="right"
                    sx={{
                      fontSize: 18,
                      fontWeight: 'bold',
                      color: 'yellow',
                      textShadow: blackShadow
                    }}
                  >
                    {(mileston?.hascheckeddaily && l === 'daily-check') ||
                    (mileston?.hasretweetted && l === 'like-retweets') ||
                    (mileston?.haspublishedonx && l === 'create-writex-post') ||
                    (mileston?.hasdailypurchased && l === 'daily-purchase') ? (
                      <Check
                        color="success"
                        sx={{ textShadow: blackShadow, fontWeight: 'bold' }}
                      />
                    ) : (
                      t('LETSGO')
                    )}
                  </Typography>
                )}
              </Grid>
            ))}
          </Grid>
          <Grid
            item
            container
            direction="column"
            sx={{
              pr: 'calc(8%)',
              mt: 'calc(16%)'
            }}
          >
            {LINKS_2.map((l, ix) => (
              <Grid
                key={ix + '_2'}
                item
                sx={{
                  mb: 'calc(14.5%)'
                }}
                onClick={async () => {
                  if (l === 1) {
                    await apiPointsHandlers.setXFollowing(true, user?.id || '');
                    window.location.href = 'https://x.com/BurnextBurn';
                  } else {
                    await apiPointsHandlers.setTelegramFollowing(
                      true,
                      user?.id || ''
                    );
                    window.location.href = 'https://t.co/OwrRotIigv';
                  }
                }}
              >
                <Typography
                  color="white"
                  textAlign="right"
                  sx={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: 'yellow',
                    textShadow: blackShadow
                  }}
                >
                  {(mileston?.isfollowingx && l === 1) ||
                  (mileston?.isfollowingtelegram && l === 2) ? (
                    <Check
                      color="success"
                      sx={{ textShadow: blackShadow, fontWeight: 'bold' }}
                    />
                  ) : (
                    t('LETSGO')
                  )}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid
            item
            container
            sx={{
              mt: 'calc(13%)'
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                pb: 'calc(25%)'
              }}
              onClick={() => alert('Ciao1')}
            >
              <Typography
                color="white"
                textAlign="right"
                sx={{
                  fontSize: 18
                }}
              >
                {' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                pb: 'calc(25%)'
              }}
              onClick={async () => {
                try {
                  const link_ =
                    process.env.REACT_APP_BASE_URL +
                    '/extended-sidebar/dashboards/' +
                    refCode;
                  navigator.clipboard.writeText(link_).then(() => {
                    setDialogMission({
                      handler: () => {
                        setDialogMission(undefined);
                      },
                      mission: 'Referal link copy [' + link_ + ']',
                      open: true
                    });
                  });
                } catch (err) {
                  console.error('Failed to copy link: ', err);
                  alert(err);
                }
              }}
            >
              <Typography
                color="white"
                textAlign="right"
                sx={{
                  fontSize: 18
                }}
              >
                {' '}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* <Footer /> */}
      </Box>
    </>
  );
}

export default DashboardCrypto;
