import createdSupabaseClient from 'src/handlers/supabaseClient';

const setWriteX = async (body: string, hashtags?: string) => {
  const hash =
    hashtags === undefined || hashtags === ''
      ? null
      : hashtags.replace(' ', '');
  const { data, error } = await createdSupabaseClient
    .from('writex')
    .insert([{ body, hashtags: hash }])
    .select();
  if (error) {
    console.log('InsertionError-WriteX: ', error);
    return error;
  }
  return data;
}; // setWriteX

export default setWriteX;
