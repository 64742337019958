// @ts-nocheck
const enJSON = {
  'DESC-LOGO': 'Back to home page',
  'Fill in the fields below or press Social button to sign into your account.':
    'Fill in the fields below or press Social button to sign into your account.',
  email_label: 'Insert e-mail',
  password_label: 'Insert password',
  'DISCLAMER-TWITTER-LOGIN':
    '(ATTENTION: if you are not able to login, please update your twitter account with a valid e-mail)',
  LETSGO: "Let's go"
};

export default enJSON;
