import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth, { IAuth0Ctx } from 'src/hooks/useAuth';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
import { useContext, useEffect, useState } from 'react';
import { AuthProvider } from './contexts/TayAuthContext';
import { SessionContext } from './AppLoader';
// import { AuthProvider } from 'src/contexts/TayAuthContext';

const App = () => {
  const session = useContext(SessionContext);
  const content = useRoutes(router);
  const { isInitialized, loginWithPopup, login } = useAuth() as IAuth0Ctx;

  useEffect(() => {
    if (session) {
      if (session?.user?.app_metadata?.provider === 'email') {
        login().then();
      }
      if (session?.user?.app_metadata?.provider === 'twitter') {
        loginWithPopup().then();
      }
    }
  }, [session?.user?.id]);

  /* useEffect(() => {
    (async () => {
      // Register this immediately after calling createClient!
      // Because signInWithOAuth causes a redirect, you need to fetch the
      // provider tokens from the callback.
      const { data } = createdSupabaseClient.auth.onAuthStateChange(
        async (event, session) => {
          if (session && session.provider_token) {
            window.localStorage.setItem(
              'oauth_provider_token',
              session.provider_token
            );
            console.log('AEEE: ', session.user);
            if (session.user.app_metadata.provider === 'email') {
              login().then();
            } else {
              loginWithPopup().then();
            }
          }

          if (session && session.provider_refresh_token) {
            window.localStorage.setItem(
              'oauth_provider_refresh_token',
              session.provider_refresh_token
            );
          }

          if (event === 'SIGNED_OUT') {
            window.localStorage.removeItem('oauth_provider_token');
            window.localStorage.removeItem('oauth_provider_refresh_token');
          }
        }
      );
      return () => {
        data.subscription.unsubscribe();
      };
    })();
  }, []); */

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          {isInitialized ? content : <AppInit />}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;
