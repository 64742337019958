// @ts-nocheck
const enJSON = {
  'DESC-LOGO': 'Torna alla home',
  'Sign in': 'Accedi',
  'Fill in the fields below or press Social button to sign into your account.':
    'Compila i campi sottostanti o premi il pulsante Social per accedere al tuo account.',
  email_label: 'Inserire e-mail',
  password_label: 'Inserire password',
  'DISCLAMER-TWITTER-LOGIN':
    '(ATTENZIONE: se non riuscite a collegarvi, controllate che il vostro account twitter sia opportunamente provvisto di e-mail valida)',
  LETSGO: "Let's go"
};

export default enJSON;
