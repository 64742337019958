import { Box, Button, CircularProgress, Grid, TextField } from '@mui/material';
import { baseCryptoUrl } from '..';
import { useNavigate } from 'react-router';
import { ArrowBack } from '@mui/icons-material';
import { useState } from 'react';
import createdSupabaseClient from 'src/handlers/supabaseClient';
import { ITweet } from './LikeRetweet';
import { TwitterShareButton } from 'react-share';
import setWriteX from './apiFunctions/setWriteX';

const WriteXForm = () => {
  const navigate = useNavigate();
  const [val, setVal] = useState('');
  const [hash, setHash] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const back = () => {
    navigate(baseCryptoUrl + '/crypto');
  }; // back

  const handleChange = (e: any) => {
    setVal(e.target.value);
  }; // handleChange

  const handleHashChange = (e: any) => {
    setHash(e.target.value);
  }; // handleHashChange

  const confirm = async () => {
    setIsLoading(true);
    await setWriteX(val, hash);
    setIsLoading(false);
    back();
  }; // confirm

  return (
    <Box sx={{ width: '100%' }}>
      {isLoading && (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      <Button
        onClick={back}
        fullWidth
        variant="contained"
        sx={{ borderRadius: 0 }}
        startIcon={<ArrowBack />}
      >
        Annulla
      </Button>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <TextField
            sx={{ p: 1 }}
            fullWidth
            multiline
            rows={5}
            label="Insert post body"
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={{ p: 1 }}
            fullWidth
            label="Insert hashtags"
            onChange={handleHashChange}
            variant="outlined"
            helperText={
              'Devono essere stringhe separate da virgola senza spazi ne virgolette:\n "ape,lupo"'
            }
          />
        </Grid>
      </Grid>

      <TwitterShareButton
        title={`${val}\n\n`}
        hashtags={hash ? hash.split(',') : undefined}
        url={window.localStorage.getItem('tw_url') + '\n'}
        style={{ width: '100%' }}
      >
        <Button
          fullWidth
          variant="contained"
          sx={{ borderRadius: 0 }}
          color="info"
        >
          PREVIEW
        </Button>
      </TwitterShareButton>

      <Button
        onClick={confirm}
        fullWidth
        variant="contained"
        sx={{ borderRadius: 0, mt: 2 }}
        color="success"
      >
        Conferma
      </Button>
    </Box>
  );
}; // WriteXForm

export default WriteXForm;
