import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
// import { ITwitterPayload } from './Auth0Context';
import supabaseClient from 'src/handlers/supabaseClient';
import PropTypes from 'prop-types';
import { ITwitterUser } from './ITwitterUser';
import { User } from '@supabase/supabase-js';
import { SessionContext } from 'src/AppLoader';
import getBurnextTwUrl from 'src/content/dashboards/Crypto/BurnNextBox/apiFunctions/getBurnextTwUrl';

export interface ITwitterPayload {
  isAuthenticated?: boolean;
  isInitialized?: boolean;
  user: ITwitterUser | undefined | null;
}

const initialAuthState: ITwitterPayload = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

type IHandlersIndex = 'LOGOUT' | 'LOGIN' | 'INITIALIZE';

interface ITwitterAction {
  type: IHandlersIndex;
  payload: ITwitterPayload;
}

interface IHandlers {
  INITIALIZE: (state: any, action: ITwitterAction) => any;
  LOGIN: (state: any, action: ITwitterAction) => any;
  LOGOUT: (state: any, action: ITwitterAction) => any;
}

const handlers: IHandlers = {
  INITIALIZE: (state: any, action: ITwitterAction) => {
    const { isAuthenticated, user } = action.payload as ITwitterPayload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: any, action: ITwitterAction) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: any, action: ITwitterAction) => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state: any, action: ITwitterAction) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialAuthState,
  method: 'Auth0',
  loginWithPopup: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = (props: any) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const session = useContext(SessionContext);
  const user = session?.user;
  useEffect(() => {
    (async () => {
      try {
        const isAuthenticated = !!user;
        if (isAuthenticated) {
          const tw_url = window.localStorage.getItem('tw_url');
          if (!tw_url || tw_url === 'x.com') {
            const tw = await getBurnextTwUrl();
            window.localStorage.setItem('tw_url', tw);
          } // if - tw_url
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    })();
  }, [user?.updated_at]);

  // Auth0 login
  const loginWithPopup = async (options: any) => {
    const isAuthenticated = !!user;
    // Retrievesuser role
    const { data: user_roles, error } = await supabaseClient
      .from('user_roles')
      .select('*')
      .eq('user_id', user?.id || '1')
      .eq('role_id', 1);
    if (error) console.log('ERRORE - Ruolo: ', error);
    const isAdmin =
      user_roles?.filter((ur) => Number(ur.role_id) === 1)?.[0]?.role_id === 1;
    if (isAuthenticated) {
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            ...user,
            role_id: isAdmin ? 1 : 2
          }
        }
      });
    }
  };

  const login = async (options: any) => {
    const isAuthenticated = !!user;
    // Retrievesuser role
    const { data: user_roles, error } = await supabaseClient
      .from('user_roles')
      .select('*')
      .eq('user_id', user?.id || 1)
      .eq('role_id', 1);
    if (error) console.log('ERRORE - Ruolo: ', error);
    const isAdmin =
      user_roles?.filter((ur) => Number(ur.role_id) === 1)?.[0]?.role_id === 1;
    if (isAuthenticated) {
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            ...user,
            role_id: isAdmin ? 1 : 2
          }
        }
      });
    }
  };

  const logout = () => {
    (async () => {
      try {
        const { error } = await supabaseClient.auth.signOut();
        if (error) console.log('Error-Logout: ', error || 'No Error detected!');
        dispatch({
          type: 'LOGOUT',
          payload: { user: null }
        });
      } catch (e) {
        console.log(e);
      }
    })();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Auth0',
        loginWithPopup,
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}; // SupabaseAuthContext

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
