// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { themeCreator } from './base';

// import { CacheProvider } from '@emotion/react';
// import createCache from '@emotion/cache';
// import stylisRTLPlugin from 'stylis-plugin-rtl';

// const cacheRtl = createCache({
//   key: 'tay-ui',
// prepend: true,
//
//   stylisPlugins: [stylisRTLPlugin]
// });
export type ISetterCtx = (themeName: string) => void;
const defaultName = 'PureLightTheme';
// export const ThemeSetterCtx = React.createContext<React.Context<null>(null);
// export const ThemeSetterCtx = React.createContext<React.Dispatch<React.SetStateAction<string>> | null>(null);
export const ThemeSetterCtx = React.createContext<ISetterCtx>(() => null);
export const ThemeContext = React.createContext(defaultName);

const ThemeProviderWrapper = function (props: any) {
  const curThemeName = localStorage.getItem('appTheme') || defaultName;
  const [themeName, setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setTNam = (themeName: string) => {
    localStorage.setItem('appTheme', themeName);
    setThemeName(themeName);
  };

  useEffect(() => {
    setThemeName(themeName);
    setTNam(themeName);
  }, [themeName]);

  return (
    <StylesProvider injectFirst>
      {/* <CacheProvider value={cacheRtl}> */}
      {/* <ThemeContext.Provider value={setThemeName}> */}
      <ThemeSetterCtx.Provider value={setTNam}>
        <ThemeContext.Provider value={themeName}>
          <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
        </ThemeContext.Provider>
      </ThemeSetterCtx.Provider>
      {/* </CacheProvider> */}
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
