import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import { ReactElement, Suspense, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { TweetSkeleton, EmbeddedTweet, TweetNotFound } from 'react-tweet';
import { Tweet, getTweet } from 'react-tweet/api';
import createdSupabaseClient, {
  corsHeaders
} from 'src/handlers/supabaseClient';
import { Add, ArrowBack } from '@mui/icons-material';
import { baseCryptoUrl } from '..';
import useAuth from 'src/hooks/useAuth';
import { TwitterShareButton } from 'react-share';
import apiPointsHandlers from './apiPointsHandlers';

export interface ITweet {
  tweetId: string;
  data: Tweet;
}
const TweetPage = ({ id }: { id: string }) => {
  const [tweet, setTweet] = useState<ITweet | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const { data: tw_row, error: err } = await createdSupabaseClient
          .from('twitte_bk')
          .select('*')
          .eq('tweetId', id)
          .order('createTime', { ascending: false })
          .limit(1)
          .single();
        if (err) console.error('Error-getdailyPost', err);
        if (tw_row) {
          setTweet({
            tweetId: id,
            data: JSON.parse(tw_row.data as string)
          });
        } else {
          // altrimenti controllo chiamo tweetter per salvare in redis e db
          const newPost = await getTweet(id, {
            headers: corsHeaders
          });
          setTweet({
            tweetId: id,
            data: newPost!
          });
        } // if - in database
      } catch (error) {
        console.error('My ERRORREEEE!!: ', error);
        return <TweetNotFound error={error} />;
      }
    })();
  }, []);
  return tweet ? <EmbeddedTweet tweet={tweet.data} /> : <TweetSkeleton />;
};

/**
 * Contains link to the actual post that will be updated on daily bases
 */
const LikeRetweet = () => {
  const [tweetId, setTweetId] = useState<string | undefined>(undefined);
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasError, setHasError] = useState<ReactElement | undefined>(undefined);
  const navigate = useNavigate();
  const { user } = useAuth();
  // needs to load the link from the db (deve prendere riga più recente)
  useEffect(() => {
    (async () => {
      if (user?.role_id) {
        const isAdm = user?.role_id === 1;
        setIsAdmin(isAdm);
        const tw_id = await createdSupabaseClient
          .from('latest_tweets')
          .select('tweetId')
          .order('created_at', { ascending: false })
          .limit(1)
          .single();
        if (tw_id.error) {
          setHasError(
            <>
              <Typography>
                <strong>{'Detail: '}</strong>
                {tw_id.error?.details}
              </Typography>
              <Typography>
                <strong>{'Message: '}</strong>
                {tw_id.error?.message}
              </Typography>
              <Typography>
                <strong>{'Status: '}</strong>
                {tw_id.status}
              </Typography>
            </>
          );
        }
        setTweetId(tw_id.data?.tweetId || undefined);
      } // if - user?.role_id defined
    })();
  }, [user?.role_id]);
  // go back to the previous page once like'd and retweeted (update db to give back points to the user)
  const handleBack = async () => {
    if (user) {
      const { error } = await apiPointsHandlers.setHasRetweettedOnX(
        true,
        user.id
      );
      if (error) console.log('ERROR in LikeRetweet: ', error);
    } else {
      console.log('USer not set!!');
    }
    navigate(baseCryptoUrl + '/crypto');
  };
  return (
    <Box>
      {isAdmin && (
        <Button
          onClick={() => navigate(baseCryptoUrl + '/create-daily-post')}
          fullWidth
          variant="contained"
          sx={{ borderRadius: 0, my: 1 }}
          color="info"
          endIcon={<Add />}
        >
          ADD DAILY POST
        </Button>
      )}

      {hasError && (
        <Alert severity="error">
          <AlertTitle sx={{ fontWeight: 'bolder' }}>Error</AlertTitle>
          {hasError}
        </Alert>
      )}
      <Button
        onClick={handleBack}
        fullWidth
        variant="contained"
        sx={{ borderRadius: 0, mb: -2 }}
        startIcon={<ArrowBack />}
      >
        BACK
      </Button>

      {tweetId && <TweetPage id={tweetId} />}
    </Box>
  );
}; // like retweet

export default LikeRetweet;
