import createdSupabaseClient from 'src/handlers/supabaseClient';

const getWriteX = async () => {
  const { data, error } = await createdSupabaseClient
    .from('writex')
    .select('*')
    .order('created_at', { ascending: false })
    .limit(1)
    .single();
  if (error) {
    console.log('Error-WriteX-get: ', error);
    return null;
  }
  return data;
}; // getWriteX

export default getWriteX;
