import { createClient } from '@supabase/supabase-js';
import { Database } from 'src/content/dashboards/Crypto/BurnNextBox/IDatabase';

// Questo punta in locale
/* const createdSupabaseClient = createClient<Database>(
  'http://localhost:54321',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24ifQ.625_WdcF3KHqz5amU0x2X5WWHP-OEs_4qj0ssLNHzTs'
); */

export const corsHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': 'authorization, x-client-info, apikey',
  'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE'
};

// Create a single supabase client for interacting with your database
const createdSupabaseClient = createClient<Database>(
  process.env.REACT_APP_BE_URL || 'http://localhost:54321',
  process.env.REACT_APP_PROJECT_KEY ||
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24ifQ.625_WdcF3KHqz5amU0x2X5WWHP-OEs_4qj0ssLNHzTs'
);

export default createdSupabaseClient;
