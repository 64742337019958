import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { baseCryptoUrl } from '..';
import { useNavigate } from 'react-router';
import { ArrowBack } from '@mui/icons-material';
import { useState } from 'react';
import createdSupabaseClient from 'src/handlers/supabaseClient';
import { ITweet } from './LikeRetweet';

const DailyPostForm = () => {
  const navigate = useNavigate();
  const [val, setVal] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const back = () => {
    navigate(baseCryptoUrl + '/like-retweets');
  }; // back

  const handleChange = (e: any) => {
    setVal(e.target.value);
  }; // handleChange

  const confirm = async () => {
    setIsLoading(true);
    /* await createdSupabaseClient.functions.invoke<ITweet>(
      'burnext-api/getDailyPost/' + val
    );
    console.log('FIrst call done!!'); */
    await createdSupabaseClient.functions.invoke<ITweet>(
      'burnext-api/updateDailyPost/' + val
    );
    setIsLoading(false);
    back();
  }; // confirm

  return (
    <Box sx={{ width: '100%' }}>
      {isLoading && (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      <Button
        onClick={back}
        fullWidth
        variant="contained"
        sx={{ borderRadius: 0 }}
        startIcon={<ArrowBack />}
      >
        Annulla
      </Button>
      <TextField
        sx={{ p: 1 }}
        fullWidth
        label="Insert post code number"
        onChange={handleChange}
        variant="outlined"
      />
      <Button
        onClick={confirm}
        fullWidth
        variant="contained"
        sx={{ borderRadius: 0 }}
        color="success"
      >
        Conferma
      </Button>
    </Box>
  );
}; // DailyPostForm

export default DailyPostForm;
