import { useContext } from 'react';
import JWTContext from 'src/contexts/JWTAuthContext';
import FirebaseAuth from 'src/contexts/FirebaseAuthContext';
// import Auth0 from 'src/contexts/Auth0Context';
import SupabaseAuthContext from 'src/contexts/TayAuthContext';
import { ITwitterUser } from 'src/contexts/ITwitterUser';

export interface IAuth0Ctx {
  method: string;
  loginWithPopup: () => Promise<void>;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  isAuthenticated?: boolean | undefined;
  isInitialized?: boolean | undefined;
  user: ITwitterUser | undefined | null;
}

export interface IJWTCtx {
  method: string;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  register: () => Promise<void>;
  isAuthenticated?: boolean | undefined;
  isInitialized?: boolean | undefined;
  user: ITwitterUser | undefined | null;
}

export interface IFirebaseCtx {
  method: string;
  createUserWithEmailAndPassword: () => Promise<void>;
  signInWithEmailAndPassword: () => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  logout: () => Promise<any>;
  isAuthenticated?: boolean | undefined;
  isInitialized?: boolean | undefined;
  user: ITwitterUser | undefined | null;
}

type IUseAuthCtx = IAuth0Ctx | IJWTCtx | IFirebaseCtx;

/* const getContext = () => {
  if (useContext(Auth0)?.user) {
    console.log('USR: ', useContext(Auth0)?.user);
    return useContext(Auth0).user?.app_metadata?.provider === 'email'
      ? 'JWT'
      : 'Auth0';
  }
  if (useContext(FirebaseAuth)?.user) return 'FirebaseAuth';
}; */

const useAuth = (ctx?: string): IUseAuthCtx => {
  return useContext<IAuth0Ctx>(SupabaseAuthContext);
  /* const etichetta = ctx || getContext();
  console.log('ETICHETTA: ', etichetta);
  switch (etichetta) {
    case 'JWT':
      return useContext<IJWTCtx>(JWTContext as any);
    case 'FirebaseAuth':
      return useContext<IFirebaseCtx>(FirebaseAuth as any);
    case 'Auth0':
      return useContext<IAuth0Ctx>(SupabaseAuthContext);

    default:
      // return useContext(JWTContext);
      return useContext<IAuth0Ctx>(SupabaseAuthContext);
  } */
};

export default useAuth;
