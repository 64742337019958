// @ts-nocheck
export default interface IInternalColor {
  gradients: IGradients;
  shadows: Partial<IShadows>;
  layout: ILayout;
  alpha: IAlpha;
  secondary: ISecondary;
  primary: IPrimary;
  success: ISuccess;
  warning: IWarning;
  error: IError;
  info: IInfo;
}

export interface IGradients {
  blue1: string;
  blue2: string;
  blue3: string;
  blue4: string;
  blue5: string;
  orange1: string;
  orange2: string;
  orange3: string;
  purple1: string;
  purple3: string;
  pink1: string;
  pink2: string;
  green1: string;
  green2: string;
  black1: string;
  black2: string;
}

export interface IShadows {
  success: string;
  error: string;
  info: string;
  primary: string;
  warning: string;
  card: string;
  cardSm: string;
  cardLg: string;
}

export interface ILayout {
  general: Partial<IGeneral>;
  sidebar: ISidebar;
}

export interface IGeneral {
  bodyBg: string;
}

export interface ISidebar {
  background: string;
  textColor: string;
  dividerBg: string;
  menuItemColor: string;
  menuItemColorActive: string;
  menuItemBg: string;
  menuItemBgActive: string;
  menuItemIconColor: string;
  menuItemIconColorActive: string;
  menuItemHeadingColor: string;
}

export interface IAlpha {
  white: IWhite;
  trueWhite: ITrueWhite;
  black: IBlack;
}

export interface IWhite {
  '5': string;
  '10': string;
  '30': string;
  '50': string;
  '70': string;
  '100': string;
}

export interface ITrueWhite {
  '5': string;
  '10': string;
  '30': string;
  '50': string;
  '70': string;
  '100': string;
}

export interface IBlack {
  '5': string;
  '10': string;
  '30': string;
  '50': string;
  '70': string;
  '80': string;
  '100': string;
}

export interface ISecondary {
  lighter: string;
  light: string;
  main: string;
  dark: string;
}

export interface IPrimary {
  lighter: string;
  light: string;
  main: string;
  dark: string;
}

export interface ISuccess {
  lighter: string;
  light: string;
  main: string;
  dark: string;
}

export interface IWarning {
  lighter: string;
  light: string;
  main: string;
  dark: string;
}

export interface IError {
  lighter: string;
  light: string;
  main: string;
  dark: string;
}

export interface IInfo {
  lighter: string;
  light: string;
  main: string;
  dark: string;
}

export interface IThemeGeneral {
  reactFrameworkColor: string;
  borderRadiusSm: string;
  borderRadius: string;
  borderRadiusLg: string;
  borderRadiusXl: string;
  loginBg: string;
}

export interface IThemeSidebar {
  background: string;
  textColor: string;
  dividerBg: string;
  menuItemColor: string;
  menuItemColorActive: string;
  menuItemBg: string;
  menuItemBgActive: string;
  menuItemIconColor: string;
  menuItemIconColorActive: string;
  menuItemHeadingColor: string;
  boxShadow: string;
  width: string;
}

export interface IThemeHeader {
  height: string;
  background: string;
  boxShadow: string;
  textColor: string;
}

export const DarkBackground = '#0b1a32';
