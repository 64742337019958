import ReactDOM from 'react-dom/client';
import 'src/mocks';
import 'src/utils/chart';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';

import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import store from 'src/store';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
// import { AuthProvider } from 'src/contexts/Auth0Context';

import reportWebVitals from './reportWebVitals';
import AppLoader from './AppLoader';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HelmetProvider>
    <Provider store={store}>
      <SidebarProvider>
        <BrowserRouter>
          <ScrollTop />
          <AppLoader />
        </BrowserRouter>
      </SidebarProvider>
    </Provider>
  </HelmetProvider>
);

// reportWebVitals(console.log);
serviceWorker.unregister();
